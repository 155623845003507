window.jQuery = window.$ = require('jquery');
require('slick-carousel/slick/slick');
var processInclude = require('./util');

$(document).ready(function () {
    processInclude(require('./components/consentTracking'));
    processInclude(require('./gtm/gtm'));
    processInclude(require('./product/tile'));
    processInclude(require('./components/menu'));
    processInclude(require('./components/cookie'));
    processInclude(require('./components/sessionVariables'));
    processInclude(require('./components/header'));
    processInclude(require('./components/footer'));
    processInclude(require('./components/miniCart'));
    processInclude(require('./components/collapsibleItem'));
    processInclude(require('./components/search'));
    processInclude(require('./components/heroBannerImage'));
    processInclude(require('./components/clientSideValidation'));
    processInclude(require('./components/countrySelector'));
    processInclude(require('./components/toolTip'));
    processInclude(require('./marketingEinstein/trackCollect'));
    processInclude(require('./privacy/privacy'));
    processInclude(require('./faqPage/faqPage'));
    processInclude(require('./thirdParty/select2.min'));
});

require('./thirdParty/bootstrap');
require('./components/spinner');
require('./thirdParty/select2.min');
require('./helpers/lazyload');

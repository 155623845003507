'use strict';

var base = require('../product/base');
var focusHelper = require('../components/focus');
var changeMinicartIcon = require('../components/changeMinicartIcon');
var sasModal = require('./sasModal');

/**
 * appends params to a url
 * @param {string} url - Original url
 * @param {Object} params - Parameters to append
 * @returns {string} result url with appended parameters
 */
function appendToUrl(url, params) {
    var newUrl = url;
    newUrl += (newUrl.indexOf('?') !== -1 ? '&' : '?') + Object.keys(params).map(function (key) {
        return key + '=' + encodeURIComponent(params[key]);
    }).join('&');

    return newUrl;
}

/**
 * Checks whether the basket is valid. if invalid displays error message and disables
 * checkout button
 * @param {Object} data - AJAX response from the server
 */
function validateBasket(data) {
    $('.cart-error').empty();
    if (data.valid.error) {
        if (data.valid.message) {
            var errorHtml = '';

            if (data.valid.renderedMessage) {
                errorHtml = data.valid.renderedMessage;
            } else {
                errorHtml = '<div class="alert alert-danger alert-dismissible valid-cart-error ' +
                    'fade show" role="alert">' +
                    '<button type="button" class="close" data-dismiss="alert" aria-label="Close">' +
                    '<span aria-hidden="true">&times;</span>' +
                    '</button>' + data.valid.message + '</div>';
            }

            $('.cart-error').append(errorHtml);
        } else {
            $('.cart').empty().hide();
            $('.number-of-items').empty().append(data.resources.numberOfItems);
            $('.minicart-quantity').empty().append(data.numItems);
            $('.minicart-link').attr({
                'aria-label': data.resources.minicartCountOfItems
            });
            $('.minicart .popover').empty();
            $('.minicart .popover').removeClass('show');
            changeMinicartIcon(data.numItems);
        }

        $('.checkout-btn').addClass('disabled');
    } else {
        $('.checkout-btn').removeClass('disabled');
    }
}

/**
 * re-renders the order totals and the number of items in the cart
 * @param {Object} data - AJAX response from the server
 */
function updateCartTotals(data) {
    $('.number-of-items').empty().append(data.resources.numberOfItems);
    $('.shipping-cost').empty().append(data.totals.totalShippingCost);
    $('.tax-total').empty().append(data.totals.totalTax);
    $('.grand-total').empty().append(data.totals.grandTotalCartValue);
    $('.sub-total').empty().append(data.totals.merchandizeTotalPrice);
    $('.minicart-quantity').empty().append(data.numItems);
    changeMinicartIcon(data.numItems);
    $('.minicart-link').attr({
        'aria-label': data.resources.minicartCountOfItems
    });

    // Update the FreeDeliveryNotice text
    $('.js-free-delivery-notice')
        .html(data.totals.freeDeliveryNotice)
        .toggleClass('d-none', !data.totals.freeDeliveryNotice)
        .toggleClass('success', data.totals.isShippingFree);

    if (data.totals.totalDiscounts && data.totals.totalDiscounts.totalPromotionDiscount) {
        $('.js-total-discount').removeClass('hide-order-discount');
        $('.js-total-discount-value').empty().append(data.totals.totalDiscounts.totalPromotionDiscount);
    } else {
        $('.js-total-discount').addClass('hide-order-discount');
    }

    if (data.totals.totalDiscounts && data.totals.totalDiscounts.totalSORPromotionDiscount) {
        $('.js-sor-discount').removeClass('hide-order-discount');
        $('.js-sor-discount-value').empty().append(data.totals.totalDiscounts.totalSORPromotionDiscount);
    } else {
        $('.js-sor-discount').addClass('hide-order-discount');
    }

    if (data.totals.totalDiscounts && data.totals.totalDiscounts.totalRewardDiscount) {
        $('.js-reward-discount').removeClass('hide-order-discount');
        $('.js-reward-discount-value').empty().append(data.totals.totalDiscounts.totalRewardDiscount);
    } else {
        $('.js-reward-discount').addClass('hide-order-discount');
    }

    if (data.totals.totalDiscounts && data.totals.totalDiscounts.totalCouponDiscount) {
        $('.coupon-field-wrapper').addClass('d-none');
        $('.js-coupon-discount').removeClass('hide-order-discount');
        $('.js-coupon-discount-value').empty().append(data.totals.totalDiscounts.totalCouponDiscount);
        $('.js-coupon-discount-label').empty().append(data.totals.totalDiscounts.couponCodeLabel);
    } else {
        if ($('.add-coupon-block').hasClass('d-none')) {
            $('.coupon-field-wrapper').removeClass('d-none');
        }
        $('.js-coupon-discount').addClass('hide-order-discount');
    }

    data.items.forEach(function (item) {
        $('.uuid-' + item.UUID + ' .unit-price').empty().append(item.renderedLinePrice);
        $('.line-item-price-' + item.UUID + ' .unit-price').empty().append(item.renderedLinePrice);
    });

    if (data.rewardPointsApplied) {
        $('.js-reward-points-content').hide();
        $('.js-reward-points-applied').show();
    } else {
        if (data.rewardPoints == 0) {
            $('.js-reward-points-content').hide();
            $('.js-reward-points-applied').hide();
        } else {
            $('.points-amount').html(data.rewardPoints);
            $('.js-reward-points-content').show();
            $('.js-reward-points-applied').hide();
        }
    }
}

/**
 * re-renders the order totals and the number of items in the cart
 * @param {Object} message - Error message to display
 */
function createErrorNotification(message) {
    var errorHtml = '<div class="alert alert-danger alert-dismissible valid-cart-error ' +
        'fade show" role="alert">' +
        '<button type="button" class="close" data-dismiss="alert" aria-label="Close">' +
        '<span aria-hidden="true">&times;</span>' +
        '</button>' + message + '</div>';

    $('.cart-error').append(errorHtml);
}

/**
 * re-renders the approaching discount messages
 * @param {Object} approachingDiscounts - updated approaching discounts for the cart
 */
function updateApproachingDiscounts(approachingDiscounts) {
    var html = '';
    $('.approaching-discounts').empty();
    if (approachingDiscounts.length > 0) {
        approachingDiscounts.forEach(function (item) {
            html += '<div class="single-approaching-discount text-center">'
                + item.discountMsg + '</div>';
        });
    }
    $('.approaching-discounts').append(html);
}

/**
 * Updates the availability of a product line item
 * @param {Object} data - AJAX response from the server
 * @param {string} uuid - The uuid of the product line item to update
 */
function updateAvailability(data, uuid) {
    var lineItem;
    var messages = '';

    for (var i = 0; i < data.items.length; i++) {
        if (data.items[i].UUID === uuid) {
            lineItem = data.items[i];
            break;
        }
    }

    if (lineItem != null) {
        $('.availability-' + lineItem.UUID).empty();

        if (lineItem.availability) {
            if (lineItem.availability.messages) {
                lineItem.availability.messages.forEach(function (message) {
                    messages += '<p class="line-item-attributes">' + message + '</p>';
                });
            }

            if (lineItem.availability.inStockDate) {
                messages += '<p class="line-item-attributes line-item-instock-date">'
                    + lineItem.availability.inStockDate
                    + '</p>';
            }
        }

        $('.availability-' + lineItem.UUID).html(messages);
    }
}

/**
 * Finds an element in the array that matches search parameter
 * @param {array} array - array of items to search
 * @param {function} match - function that takes an element and returns a boolean indicating if the match is made
 * @returns {Object|null} - returns an element of the array that matched the query.
 */
function findItem(array, match) { // eslint-disable-line no-unused-vars
    for (var i = 0, l = array.length; i < l; i++) {
        if (match.call(this, array[i])) {
            return array[i];
        }
    }
    return null;
}

/**
 * Updates details of a product line item
 * @param {Object} data - AJAX response from the server
 * @param {string} uuid - The uuid of the product line item to update
 */
function updateProductDetails(data, uuid) {
    $('.card.product-info.uuid-' + uuid).replaceWith(data.renderedTemplate);
}

/**
 * Generates the modal window on the first call.
 *
 */
function getModalHtmlElement() {
    if ($('#editProductModal').length !== 0) {
        $('#editProductModal').remove();
    }
    var htmlString = '<!-- Modal -->'
        + '<div class="modal fade" id="editProductModal" tabindex="-1" role="dialog">'
        + '<span class="enter-message sr-only" ></span>'
        + '<div class="modal-dialog quick-view-dialog">'
        + '<!-- Modal content-->'
        + '<div class="modal-content">'
        + '<div class="modal-header">'
        + '    <button type="button" class="close pull-right" data-dismiss="modal">'
        + '        <span aria-hidden="true">&times;</span>'
        + '        <span class="sr-only"> </span>'
        + '    </button>'
        + '</div>'
        + '<div class="modal-body"></div>'
        + '<div class="modal-footer"></div>'
        + '</div>'
        + '</div>'
        + '</div>';
    $('body').append(htmlString);
}

/**
 * Parses the html for a modal window
 * @param {string} html - representing the body and footer of the modal window
 *
 * @return {Object} - Object with properties body and footer.
 */
function parseHtml(html) {
    var $html = $('<div>').append($.parseHTML(html));

    var body = $html.find('.product-quickview');
    var footer = $html.find('.modal-footer').children();

    return { body: body, footer: footer };
}

/**
 * replaces the content in the modal window for product variation to be edited.
 * @param {string} editProductUrl - url to be used to retrieve a new product model
 */
function fillModalElement(editProductUrl) {
    $('.modal-body').spinner().start();
    $.ajax({
        url: editProductUrl,
        method: 'GET',
        dataType: 'json',
        success: function (data) {
            var parsedHtml = parseHtml(data.renderedTemplate);

            $('#editProductModal .modal-body').empty();
            $('#editProductModal .modal-body').html(parsedHtml.body);
            $('#editProductModal .modal-footer').html(parsedHtml.footer);
            $('#editProductModal .modal-header .close .sr-only').text(data.closeButtonText);
            $('#editProductModal .enter-message').text(data.enterDialogMessage);
            $('#editProductModal').modal('show');
            $('body').trigger('editproductmodal:ready');
            $.spinner().stop();
        },
        error: function () {
            $.spinner().stop();
        }
    });
}

/**
 * replace content of modal
 * @param {string} actionUrl - url to be used to remove product
 * @param {string} productID - pid
 * @param {string} productName - product name
 * @param {string} uuid - uuid
 */
function confirmDelete(actionUrl, productID, productName, uuid, gtmItemData) {
    var $deleteConfirmBtn = $('.cart-delete-confirmation-btn');
    var $productToRemoveSpan = $('.product-to-remove');

    $deleteConfirmBtn.data('item-bundled-products', gtmItemData.itemBundledProducts);
    $deleteConfirmBtn.data('pid', productID);
    $deleteConfirmBtn.data('action', actionUrl);
    $deleteConfirmBtn.data('uuid', uuid);
    $deleteConfirmBtn.data('item-id', gtmItemData.itemId);
    $deleteConfirmBtn.data('item-name', gtmItemData.itemName);
    $deleteConfirmBtn.data('item-brand', gtmItemData.itemBrand);
    $deleteConfirmBtn.data('item-category', gtmItemData.itemCategory);
    $deleteConfirmBtn.data('item-category2', gtmItemData.itemCategory2);
    $deleteConfirmBtn.data('item-category3', gtmItemData.itemCategory3);
    $deleteConfirmBtn.data('item-category4', gtmItemData.itemCategory4);
    $deleteConfirmBtn.data('item-category5', gtmItemData.itemCategory5);
    $deleteConfirmBtn.data('item-variant', gtmItemData.itemVariant);
    $deleteConfirmBtn.data('item-price', gtmItemData.itemPrice);
    $deleteConfirmBtn.data('item-quantity', gtmItemData.itemQuantity);
    $deleteConfirmBtn.data('item-discount', gtmItemData.itemDiscount);

    $productToRemoveSpan.empty().append(productName);
}

/**
 * Configures recommendations carousel
 */
function recommendationsConfig() {
    var Swiper = require('swiper');
    var dataSlideThreshold = $('.recommendations-wrapper').attr('data-slidethreshold');
    var slideThreshold = dataSlideThreshold ? parseInt(dataSlideThreshold, 10) : 4;
    var swiperSlideCount = $('.swiper-recommendations .product').length;
    var isSwiperSlideCountOverThreshold = swiperSlideCount > slideThreshold;
    var slidesPerView = isSwiperSlideCountOverThreshold ? slideThreshold : swiperSlideCount;

    var recommendationsSwiper = new Swiper('.swiper-recommendations', {
        zoom: false,
        pagination: '.swiper-pagination-recommendations',
        slidesPerView: slidesPerView,
        centeredSlides: false,
        paginationClickable: true,
        keyboardControl: false,
        grabCursor: true,
        nextButton: '.swiper-button-next',
        prevButton: '.swiper-button-prev',
        loop: isSwiperSlideCountOverThreshold,
        onInit: function () {
            if (!isSwiperSlideCountOverThreshold) {
                $('.swiper-button-prev').hide();
                $('.swiper-button-next').hide();
            }
        },
        onAfterResize: function () {
            recommendationsSwiper.updateSlidesSize();
            recommendationsSwiper.updateSlidesOffset();
        },
        breakpoints: {
            584: {
                slidesPerView: swiperSlideCount > 1 ? 1.5 : 1,
                centeredSlides: true
            },
            768: {
                slidesPerView: swiperSlideCount > 2 ? 2.5 : swiperSlideCount - 0.5,
                centeredSlides: isSwiperSlideCountOverThreshold
            },
            1024: {
                slidesPerView: swiperSlideCount > 3 ? 2.5 : swiperSlideCount - 0.5,
                centeredSlides: isSwiperSlideCountOverThreshold
            }
        }
    });

    var viewSize = $(window).width();
    if (viewSize < 584) {
        if (swiperSlideCount > 1) {
            slidesPerView = 1.5;
        }
        else {
            slidesPerView = 1;
        }
    } else if ((viewSize >= 584) && (viewSize < 768)) {
        if (swiperSlideCount > 2) {
            slidesPerView = 2.5;
        }
        else {
            slidesPerView = swiperSlideCount - 0.5;
        }
    } else if ((viewSize >= 7688) && (viewSize < 1024)) {
        if (swiperSlideCount > 2) {
            slidesPerView = 2.5;
        }
        else {
            slidesPerView = swiperSlideCount - 0.5;
        }
    }
    
    // add the data outofinitview equal to true to the elements that are not visible
    $('.swiper-recommendations').attr('numberSlides', slidesPerView);
    var swiper = $('.swiper-recommendations');
    var items = swiper.find('.swiper-slide');
    var firstVisiblePos = swiper.find('.swiper-slide-active').index();
    var lastVisiblePos =  firstVisiblePos + slidesPerView;
    for (var i = 0; i < items.length; i++) {
        if (i < firstVisiblePos || i >= lastVisiblePos) {
            items.eq(i).attr('data-outofinitview', true);
        }
    }
    
    $(window).on('resize', function () {
        recommendationsSwiper.updateSlidesSize();
        recommendationsSwiper.updateSlidesOffset();
    });
}

module.exports = function () {
    $('body').on('click', '.remove-product', function (e) {
        e.preventDefault();

        var actionUrl = $(this).data('action');
        var productID = $(this).data('pid');
        var productName = $(this).data('name');
        var uuid = $(this).data('uuid');
        confirmDelete(actionUrl, productID, productName, uuid, $(this).data());
    });

    $('body').on('afterRemoveFromCart', function (e, data) {
        e.preventDefault();
        confirmDelete(data.actionUrl, data.productID, data.productName, data.uuid);
    });

    $('.optional-promo').click(function (e) {
        e.preventDefault();
        $('.promo-code-form').toggle();
    });

    $('body').on('click', '.cart-delete-confirmation-btn', function (e) {
        e.preventDefault();

        var productID = $(this).data('pid');
        var url = $(this).data('action');
        var uuid = $(this).data('uuid');
        var gtmItemData = $(this).data();
        gtmItemData.savedQuantity = parseInt($('.quantity[data-pid="'+productID+'"]').val(), 10);

        var urlParams = {
            pid: productID,
            uuid: uuid
        };

        url = appendToUrl(url, urlParams);

        $('body > .modal-backdrop').remove();

        $.spinner().start();

        $('body').trigger('cart:beforeUpdate');

        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success: function (data) {
                // add to dataLayer
                $('body').trigger('gtm:removeFromCart', gtmItemData);
                if (data.basket.items.length === 0) {
                    if ($('.page').attr('data-action') == 'Cart-Show') {
                        location.reload();
                    } else {
                        $('.cart').empty().hide();
                        $('.number-of-items').empty().append(data.basket.resources.numberOfItems);
                        $('.minicart-quantity').empty().append(data.basket.numItems);
                        changeMinicartIcon(data.basket.numItems);
                        $('.minicart-link').attr({
                            'aria-label': data.basket.resources.minicartCountOfItems
                        });
                        $('.minicart .popover').empty();
                        $('.minicart .popover').removeClass('show');
                        $('.minicart-background').removeClass('active');
                        $('body').removeClass('modal-open');
                        $('html').removeClass('veiled');
                    }
                    $('body').trigger('triggerCartEmpty');
                } else {
                    if (data.toBeDeletedUUIDs && data.toBeDeletedUUIDs.length > 0) {
                        for (var i = 0; i < data.toBeDeletedUUIDs.length; i++) {
                            $('.uuid-' + data.toBeDeletedUUIDs[i]).remove();
                        }
                    }
                    $('.uuid-' + uuid).remove();
                    if (!data.basket.hasBonusProduct) {
                        $('.bonus-product').remove();
                    }

                    if ($('.bonus-uuid-' + uuid).length && $('.page').attr('data-action') == 'Cart-Show') {
                        location.reload();
                    }

                    $('.popover.show .bonus-line-item-msg').empty().append(data.basket.resources.numberOfBonusItems);

                    $('.default-bonus-title, .bonus-item').empty();

                    for (var j = 0; j < data.basket.items.length; j++) {
                        var count = null;

                        if (data.basket.items[j].bonusProducts) {
                            count = data.basket.items[j].bonusProducts.length;
                        }

                        if (count === 0) {
                            var UUID = data.basket.items[j].UUID;
                            $(`.card.bonus-product-line-item.uuid-${UUID}`).find('.bonus-title-wrapper').empty();
                        }
                    }

                    if (!data.basket.hasSorProducts) {
                        $('.js-sor-switch-all-wrapper').remove();
                    }

                    if (data.hideAddProductToSubscriptionBtn) {
                        $('.add-to-subscription-btn').addClass('disabled');
                        $('.add-to-subscription-btn').addClass('d-none');
                    } else {
                        $('.add-to-subscription-btn').removeClass('disabled');
                        $('.add-to-subscription-btn').removeClass('d-none');
                    }

                    $('.coupons-and-promos').empty().append(data.basket.totals.discountsHtml);
                    updateCartTotals(data.basket);
                    updateApproachingDiscounts(data.basket.approachingDiscounts);
                    $('body').trigger('setShippingMethodSelection', data.basket);
                    validateBasket(data.basket);
                    $('body').trigger('triggerCartUpdate', data.basket.items);
                }

                if (data.flashMessage) {
                    $('body').append(data.flashMessage);

                    setTimeout(function () {
                        $('.flash-messages').remove();
                    }, 3000);
                }
                $('body').trigger('cart:update', data);
                $.spinner().stop();
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            }
        });
    });

    $('body').on('change', '.quantity-form > .quantity', function () {
        var preSelectQty = $(this).data('pre-select-qty');
        var quantity = $(this).val();
        var productID = $(this).data('pid');
        var url = $(this).data('action');
        var uuid = $(this).data('uuid');

        var urlParams = {
            pid: productID,
            quantity: quantity,
            uuid: uuid
        };
        url = appendToUrl(url, urlParams);

        $(this).parents('.card').spinner().start();

        $('body').trigger('cart:beforeUpdate');

        $.ajax({
            url: url,
            type: 'get',
            context: this,
            dataType: 'json',
            success: function (data) {
                $('.quantity[data-uuid="' + uuid + '"]').val(quantity);
                $('.coupons-and-promos').empty().append(data.totals.discountsHtml);

                if (data.valid.error || data.hideAddProductToSubscriptionBtn) {
                    $('.add-to-subscription-btn').addClass('disabled');
                    $('.add-to-subscription-btn').addClass('d-none');
                } else {
                    $('.add-to-subscription-btn').removeClass('disabled');
                    $('.add-to-subscription-btn').removeClass('d-none');
                }

                updateCartTotals(data);
                updateApproachingDiscounts(data.approachingDiscounts);
                updateAvailability(data, uuid);
                validateBasket(data);
                $(this).data('pre-select-qty', quantity);

                $('body').trigger('cart:update', data);

                $('body').trigger('triggerCartUpdate', data.items);

                $.spinner().stop();
                if ($(this).parents('.product-info').hasClass('bonus-product-line-item') && $('.cart-page').length) {
                    location.reload();
                }
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $(this).val(parseInt(preSelectQty, 10));
                    $.spinner().stop();
                }
            }
        });
    });

    $('.shippingMethods').change(function () {
        var url = $(this).attr('data-actionUrl');
        var urlParams = {
            methodID: $(this).find(':selected').attr('data-shipping-id')
        };
        // url = appendToUrl(url, urlParams);

        $('.totals').spinner().start();
        $('body').trigger('cart:beforeShippingMethodSelected');
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: urlParams,
            success: function (data) {
                if (data.error) {
                    window.location.href = data.redirectUrl;
                } else {
                    $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                    updateCartTotals(data);
                    updateApproachingDiscounts(data.approachingDiscounts);
                    validateBasket(data);
                }

                $('body').trigger('cart:shippingMethodSelected', data);
                $.spinner().stop();
            },
            error: function (err) {
                if (err.redirectUrl) {
                    window.location.href = err.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            }
        });
    });

    $('.promo-code-form').on('submit', function (e) {
        e.preventDefault();
        const $form = $(this);

        if ($form.data('submitted')) return;

        $.spinner().start();
        $form.data('submitted', true);
        $('.coupon-missing-error').hide();
        $('.coupon-error-message').empty();

        const $formControl = $('.form-control', $form);
        const couponCode = $formControl.val();
        const isCheckout = $('#checkout-main').length > 0;

        if (!couponCode) {
            $formControl.addClass('is-invalid').attr('aria-describedby', 'missingCouponCode');
            $('.coupon-missing-error').show();
            $.spinner().stop();
            $form.data('submitted', false);
            return false;
        }

        $formControl.removeClass('is-invalid');
        $('body').trigger('promotion:beforeUpdate');

        $.ajax({
            url: $form.attr('action'),
            type: 'GET',
            dataType: 'json',
            data: $form.serialize(),
            success: function (data) {
                if (data.error) {
                    $formControl.addClass('is-invalid').attr('aria-describedby', 'invalidCouponCode');
                    $('.coupon-error-message').empty().append(data.errorMessage);
                    $('body').trigger('promotion:error', data);
                } else {
                    if (isCheckout) {
                        location.reload();
                    } else {
                        $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                        updateCartTotals(data);
                        updateApproachingDiscounts(data.approachingDiscounts);
                        validateBasket(data);
                        $('body').trigger('promotion:success', data);
                    }
                    $formControl.val('');
                    if (data.flashMessage) {
                        $('body').append(data.flashMessage);
                        setTimeout(() => $('.flash-messages').remove(), 3000);
                    }
                }
                $form.data('submitted', false);
                $.spinner().stop();
            },
            error: function (err) {
                $('body').trigger('promotion:error', err);
                if (err.responseJSON && err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else if (isCheckout) {
                    location.reload();
                } else {
                    createErrorNotification(err.errorMessage);
                    $form.data('submitted', false);
                    $.spinner().stop();
                }
            }
        });

        return false;
    });

    $('body').on('click', '.remove-coupon', function (e) {
        e.preventDefault();

        var couponCode = $(this).data('code');
        var uuid = $(this).data('uuid');
        var $deleteConfirmBtn = $('.delete-coupon-confirmation-btn');
        var $productToRemoveSpan = $('.coupon-to-remove');

        $deleteConfirmBtn.data('uuid', uuid);
        $deleteConfirmBtn.data('code', couponCode);

        $productToRemoveSpan.empty().append(couponCode);
        $deleteConfirmBtn.trigger('click');
    });

    $('body').on('click', '.delete-coupon-confirmation-btn', function (e) {
        e.preventDefault();
        var isCheckout = $('#checkout-main').length > 0;
        var url = $(this).data('action');
        var uuid = $(this).data('uuid');
        var couponCode = $(this).data('code');
        var urlParams = {
            code: couponCode,
            uuid: uuid
        };

        url = appendToUrl(url, urlParams);

        $('body > .modal-backdrop').remove();

        $.spinner().start();
        $('body').trigger('promotion:beforeUpdate');
        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success: function (data) {
                if (isCheckout) {
                    location.reload();
                } else {
                    $('.coupon-uuid-' + uuid).remove();
                    updateCartTotals(data);
                    updateApproachingDiscounts(data.approachingDiscounts);
                    validateBasket(data);
                    $.spinner().stop();
                    $('body').trigger('promotion:success', data);
                }
            },
            error: function (err) {
                $('body').trigger('promotion:error', err);
                if (err.responseJSON && err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else if (isCheckout) {
                    location.reload();
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            }
        });
    });
    $('body').on('click', '.cart-page .bonus-product-button', function () {
        $.spinner().start();
        $(this).addClass('launched-modal');
        $.ajax({
            url: $(this).data('url'),
            method: 'GET',
            dataType: 'json',
            success: function (data) {
                base.methods.editBonusProducts(data);
                $.spinner().stop();
            },
            error: function () {
                $.spinner().stop();
            }
        });
    });

    $('body').on('hidden.bs.modal', '#chooseBonusProductModal', function () {
        $('#chooseBonusProductModal').remove();
        $('.modal-backdrop').remove();
        $('body').removeClass('modal-open');

        if ($('.cart-page').length) {
            $('.launched-modal .btn-outline-primary').trigger('focus');
            $('.launched-modal').removeClass('launched-modal');
        } else {
            $('.product-detail .add-to-cart').focus();
        }
    });

    $('body').on('click', '.cart-page .product-edit .edit, .cart-page .bundle-edit .edit', function (e) {
        e.preventDefault();

        var editProductUrl = $(this).attr('href');
        getModalHtmlElement();
        fillModalElement(editProductUrl);
    });

    $('body').on('shown.bs.modal', '#editProductModal', function () {
        $('#editProductModal').siblings().attr('aria-hidden', 'true');
        $('#editProductModal .close').focus();
    });

    $('body').on('hidden.bs.modal', '#editProductModal', function () {
        $('#editProductModal').siblings().attr('aria-hidden', 'false');
    });

    $('body').on('keydown', '#editProductModal', function (e) {
        var focusParams = {
            event: e,
            containerSelector: '#editProductModal',
            firstElementSelector: '.close',
            lastElementSelector: '.update-cart-product-global',
            nextToLastElementSelector: '.modal-footer .quantity-select'
        };
        focusHelper.setTabNextFocus(focusParams);
    });

    $('body').on('product:updateAddToCart', function (e, response) {
        // update global add to cart (single products, bundles)
        var dialog = $(response.$productContainer)
            .closest('.quick-view-dialog');

        $('.update-cart-product-global', dialog).attr('disabled',
            !$('.global-availability', dialog).data('ready-to-order')
            || !$('.global-availability', dialog).data('available')
        );
    });

    $('body').on('product:updateAvailability', function (e, response) {
        // bundle individual products
        $('.product-availability', response.$productContainer)
            .data('ready-to-order', response.product.readyToOrder)
            .data('available', response.product.available)
            .find('.availability-msg')
            .empty()
            .html(response.message);


        var dialog = $(response.$productContainer)
            .closest('.quick-view-dialog');

        if ($('.product-availability', dialog).length) {
            // bundle all products
            var allAvailable = $('.product-availability', dialog).toArray()
                .every(function (item) { return $(item).data('available'); });

            var allReady = $('.product-availability', dialog).toArray()
                .every(function (item) { return $(item).data('ready-to-order'); });

            $('.global-availability', dialog)
                .data('ready-to-order', allReady)
                .data('available', allAvailable);

            $('.global-availability .availability-msg', dialog).empty()
                .html(allReady ? response.message : response.resources.info_selectforstock);
        } else {
            // single product
            $('.global-availability', dialog)
                .data('ready-to-order', response.product.readyToOrder)
                .data('available', response.product.available)
                .find('.availability-msg')
                .empty()
                .html(response.message);
        }
    });

    $('body').on('product:afterAttributeSelect', function (e, response) {
        if ($('.modal.show .product-quickview .bundle-items').length) {
            $('.modal.show').find(response.container).data('pid', response.data.product.id);
            $('.modal.show').find(response.container).find('.product-id').text(response.data.product.id);
        } else {
            $('.modal.show .product-quickview').data('pid', response.data.product.id);
        }
    });

    $('body').on('change', '.quantity-select', function () {
        var selectedQuantity = $(this).val();
        $('.modal.show .update-cart-url').data('selected-quantity', selectedQuantity);
    });

    $('body').on('change', '.options-select', function () {
        var selectedOptionValueId = $(this).children('option:selected').data('value-id');
        $('.modal.show .update-cart-url').data('selected-option', selectedOptionValueId);
    });

    $('body').on('click', '.update-cart-product-global', function (e) {
        e.preventDefault();

        var updateProductUrl = $(this).closest('.cart-and-ipay').find('.update-cart-url').val();
        var selectedQuantity = $(this).closest('.cart-and-ipay').find('.update-cart-url').data('selected-quantity');
        var selectedOptionValueId = $(this).closest('.cart-and-ipay').find('.update-cart-url').data('selected-option');
        var uuid = $(this).closest('.cart-and-ipay').find('.update-cart-url').data('uuid');

        var form = {
            uuid: uuid,
            pid: base.getPidValue($(this)),
            quantity: selectedQuantity,
            selectedOptionValueId: selectedOptionValueId
        };

        $(this).parents('.card').spinner().start();

        $('body').trigger('cart:beforeUpdate');

        if (updateProductUrl) {
            $.ajax({
                url: updateProductUrl,
                type: 'post',
                context: this,
                data: form,
                dataType: 'json',
                success: function (data) {
                    $('#editProductModal').modal('hide');

                    $('.coupons-and-promos').empty().append(data.cartModel.totals.discountsHtml);
                    updateCartTotals(data.cartModel);
                    updateApproachingDiscounts(data.cartModel.approachingDiscounts);
                    updateAvailability(data.cartModel, uuid);
                    updateProductDetails(data, uuid);

                    if (data.uuidToBeDeleted) {
                        $('.uuid-' + data.uuidToBeDeleted).remove();
                    }

                    validateBasket(data.cartModel);

                    $('body').trigger('cart:update', data);

                    $.spinner().stop();
                },
                error: function (err) {
                    if (err.responseJSON.redirectUrl) {
                        window.location.href = err.responseJSON.redirectUrl;
                    } else {
                        createErrorNotification(err.responseJSON.errorMessage);
                        $.spinner().stop();
                    }
                }
            });
        }
    });

    $(document).on('click', '.button-plus, .button-minus', function (e) {
        var $input = $(this).siblings('.minicart-quantity-input, .cart-quantity-input');
        var stepValue = $input.attr('step');
        var minValue = $input.attr('min');
        var maxValue = $input.attr('max');
        var currentval = $(this).siblings('.minicart-quantity-input, .cart-quantity-input').val();

        if ($(e.target).hasClass('button-plus') && currentval < +maxValue) {
            $(this).siblings('.minicart-quantity-input, .cart-quantity-input').val(+currentval + +stepValue);
            $input.val(+currentval + +stepValue);
        } else if ($(e.target).hasClass('button-minus') && currentval > +minValue) {
            $(this).siblings('.minicart-quantity-input, .cart-quantity-input').val(+currentval - +stepValue);
            $input.val(+currentval - +stepValue);
        }
        $input.trigger('change');
    });

    $('body').on('click', '.redeem-points', function (e) {
        e.preventDefault();
        $.spinner().start();

        $.ajax({
            url: $(this).attr('data-action'),
            method: 'POST',
            success: function (data) {
                if (!data.error) {
                    $('body').trigger('gtm:rewardPoints', { rewardPoints: $('#hiddenRewardPoints').val() });
                    updateCartTotals(data);
                }

                if (data.flashMessage) {
                    $('body').append(data.flashMessage);

                    setTimeout(function () {
                        $('.flash-messages').remove();
                    }, 3000);
                }

                $.spinner().stop();
            },
            error: function () {
                $.spinner().stop();
            }
        });
    });

    $('body').on('click', '.undo-redeem-points', function (e) {
        e.preventDefault();
        $.spinner().start();

        $.ajax({
            url: $(this).attr('data-action'),
            method: 'POST',
            success: function (data) {
                if (!data.error) {
                    $('#hiddenRewardPoints').val(parseInt(data.rewardPoints, 10))
                    updateCartTotals(data);
                }

                $.spinner().stop();
            },
            error: function () {
                $.spinner().stop();
            }
        });
    });

    $(document).on('click', '.add-coupon-title', function () {
        $('.add-coupon-block').addClass('d-none');
        $('.coupon-field-wrapper').removeClass('d-none');
    });

    if ($('.page').attr('data-action') == 'Cart-Show') {
        recommendationsConfig();
        $('body').addClass('avoidCookiesOverlap');
        $('body').addClass('avoidLiveChatOverlap');
    }

    $(document).on('click', '#coupon-button', function () {
        $('.promo-code-form').trigger('submit');
    });

    var basketURL = ''; // Store the fetched URL

    // Function to display a success message when the URL is copied
    function showCopySuccessMessage() {
        if ($('.copy-to-clipboard-message').length === 0) {
            $('body').append('<div class="copy-to-clipboard-message"></div>');
        }
        
        $('.copy-to-clipboard-message').append(
            '<div class="alert alert-success copy-to-clipboard-alert text-center" role="alert">Link copied to clipboard</div>'
        );

        setTimeout(function () {
            $('.copy-to-clipboard-alert').remove();
        }, 5000);
    }

    // Function to copy the URL using the Clipboard API or execCommand
    function copyToClipboard($button, url) {
        if (navigator.clipboard) {
            navigator.clipboard.writeText(url).then(function () {
                showCopySuccessMessage();

                var gtmCustomerNumber = $button.data('customer');
                $('body').trigger('gtm:shareBasket', { customerNumber: gtmCustomerNumber });
            }).catch(function (err) {
                console.error('Failed to copy:', err);
            });
        } else {
            // Fallback for older browsers that do not support the Clipboard API
            var $tempInput = $('<input>');
            $tempInput.val(url);
            $('body').append($tempInput);
            $tempInput.select();
            $tempInput[0].setSelectionRange(0, 99999); // For mobile devices

            try {
                var successful = document.execCommand('copy');
                if (successful) {
                    showCopySuccessMessage();

                    var gtmCustomerNumber = $button.data('customer');
                    $('body').trigger('gtm:shareBasket', { customerNumber: gtmCustomerNumber });
                } else {
                    console.error('Failed to copy the link. Please try again.');
                }
            } catch (err) {
                console.error('Failed to copy:', err);
            }

            $tempInput.remove();
        }
    }

    // Trigger the AJAX request on mousedown (or touchstart on mobile)
    $(document).on('mousedown touchstart', '.share-basket-url-button', function () {
        var url = $(this).attr('data-url');

        $.ajax({
            url: url,
            type: 'GET',
            contentType: 'application/json',
        }).done(function (data) {
            basketURL = data.basketURL;
        }).fail(function () {
            console.error('Failed to fetch the URL. Please try again.');
        });
    });

    // Perform the clipboard copy on click event with a 400ms delay
    $(document).on('click', '.share-basket-url-button', function () {
        var $this = $(this);

        setTimeout(function () {
            if (basketURL) {
                copyToClipboard($this, basketURL);
            } else {
                if ($('.copy-to-clipboard-message').length === 0) {
                    $('body').append('<div class="copy-to-clipboard-message"></div>');
                }
                
                $('.copy-to-clipboard-message').append(
                    '<div class="alert alert-danger copy-to-clipboard-alert text-center" role="alert">Please try again</div>'
                );
        
                setTimeout(function () {
                    $('.copy-to-clipboard-alert').remove();
                }, 5000);
            }
        }, 400);
    });

    sasModal();
    base.selectAttribute();
    base.colorAttribute();
    base.removeBonusProduct();
    base.selectBonusProduct();
    base.enableBonusProductSelection();
    base.showMoreBonusProducts();
    base.addBonusProductsToCart();
    base.focusChooseBonusProductModal();
    base.trapChooseBonusProductModalFocus();
    base.onClosingChooseBonusProductModal();
};

'use strict';

module.exports = function () {
    var mainPage = $('.page');
    var mid = mainPage.attr('data-mid');
    var email = mainPage.attr('data-customer-email');
    var action = mainPage.attr('data-action');
    var querystring = mainPage.attr('data-querystring');

    if (mid && mid.length > 0) {
        window._etmc.push(['setOrgId', mid]);

        if (email && email.length > 0) {
            window._etmc.push(['setUserInfo', {'email': email}]);
        }

        if (action == 'Product-Show') {
            window._etmc.push(['trackPageView', {'item': $('.product-detail').attr('data-pid')}]);
        }

        if (action == 'Search-Show') {
            if (querystring.includes('cgid')) {
                window._etmc.push(['trackPageView', {'category': querystring.split('=')[1]}]);
            } else {
                window._etmc.push(['trackPageView', {'search': querystring.split('&')[1].split('=')[1]}]);
            }
        }

        if (action == 'Page-Show') {
            window._etmc.push(['trackPageView', {'category': querystring.split('=')[1]}]);
        }

        if (action == 'Order-Confirm') {
            var cartItems = [];

            if ($('.meal-plans-confirmation').length > 0) {
                var mealPlanConfirmProducts = JSON.parse($('.meal-plans-confirmation').attr('data-meal-plan-products'));
                for (var j = 0; j < mealPlanConfirmProducts.length; j++) {
                    var mealPlanConfirmProduct = $(mealPlanConfirmProducts[j]);
                    cartItems.push({
                        'item': mealPlanConfirmProduct.id || '',
                        'quantity': mealPlanConfirmProduct.quantity.toString() || '',
                        'price': mealPlanConfirmProduct.priceTotal.price || '',
                        'unique_id': mealPlanConfirmProduct.EAN || ''
                    });
                }
            } else {
                var products = $('.product-group').find('.product');
                for (var k = 0; k < products.length; k++) {
                    var product = $(products[k]);
                    cartItems.push({
                        'item': product.attr('data-id') || '',
                        'quantity': product.attr('data-quantity') || '',
                        'price': product.find('.product-price').text() || '',
                        'unique_id': product.attr('data-ean') || ''
                    });
                }
            }

            window._etmc.push(['trackConversion', {'cart': cartItems}]);
        }

        $('body').on('triggerCartUpdate', function (e, items) {
            var cartItems = [];
            for (var i = 0; i < items.length; i++) {
                var item = items[i];
                cartItems.push({
                    'item': item.id || '',
                    'quantity': item.quantity.toString() || '',
                    'price': item.priceTotal.price || '',
                    'unique_id': item.EAN || ''
                });
            }
            window._etmc.push(['trackCart', {'cart': cartItems}]);
        });

        $('body').on('triggerCartEmpty', function () {
            window._etmc.push(['trackCart', {'clear_cart': true}]);
        });
    }
};
